<script>
import {getFliggyCaseReplyFCountApi} from "@/www/urls/workOrder";

export default {
    name: "AppMessage",
    data() {
        return {
            list: [],
        }
    },
    computed: {
        total() {
            return this.list.reduce((a, b) => a + b.count, 0)
        }
    },
    mounted() {
        this.getData();
        this.timer = setInterval(() => {
            this.getData()
        }, 30 * 1000);
        window.bus.$on('refreshFliggyCount', () => {
            this.getFliggyCaseReplyFCount()
        })
    },
    beforeDestroy() {
        clearInterval(this.timer)
    },
    methods: {
        async getData() {
            try {
                const ret = await Promise.all([this.getFliggyCaseReplyFCount()])
                console.log(ret)
            } catch (err) {
                console.log(err);
            }
        },
        async getFliggyCaseReplyFCount() {
            try {
                if (this.$store.state.loginUserInfo.refType !== '0')
                    return
                const ret = await getFliggyCaseReplyFCountApi()
                if (ret.success) {
                    const index = this.list.findIndex(({key}) => key === 'workOrderFliggy')
                    if (index === -1) {
                        this.list.push({
                            key: 'workOrderFliggy',
                            title: '飞猪工单未读消息',
                            count: ret.data,
                            path: '#/workOrderFliggy'
                        })
                    } else this.list[index].count = ret.data
                }
            } catch (err) {
                console.log(err);
            }
        }
    }
}
</script>

<template>
    <el-popover
        placement="bottom-end"
        width="400"
        trigger="hover"
        v-if="$store.state.loginUserInfo.refType==='0'">
        <ul>
            <li v-for="vm in list" :key="vm.title" class="list-item">
                <span style="display: flex;align-items: center"> {{ vm.title }} <span
                    class="num">{{
                        vm.count
                    }}</span></span>
                <el-link :underline="false" type="primary" :href="vm.path">查看</el-link>
            </li>
        </ul>
        <el-button type="text" icon="" slot="reference" class="button">
            <img class="img" v-if="total===0" src="@/www/assets/images/message/no-message.png" alt="">
            <img class="img" v-else src="@/www/assets/images/message/new-message.png" alt="">
            <span><span :style="{color:total>0?'#FF0000':''}">{{ total }}</span>条未读</span>
        </el-button>
    </el-popover>
</template>

<style scoped lang="scss">
.button {
    margin-right: 30px;
    color: rgba(0, 0, 0, .65);
    font-weight: normal;
}

.num {
    margin-left: 20px;
    background-color: #FF0000;
    border-radius: 7.5px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 25px;
    color: #fff;
    font-size: 10px
}

.list-item {
    display: flex;
    justify-content: space-between;
}

.img {
    width: 16px;
    margin-top: -2px;
    margin-right: 8px;
}
</style>
