import http from '@/common/js/http'

/**
 * 列表
 */
export const getWorkOrderList = async (data) => {
    return http('/galaxyOrder/bmsOrderEventList', data, 'POST')
}
/**
 * 创建
 */
export const createOrderEventApi = async (data) => {
    return http('/galaxyOrder/bmsCreateOrderEvent', data, 'POST')
}
/**
 * 回复
 */
export const replyOrderEventApi = async (data) => {
    return http('/galaxyOrder/replyOrderEvent', data, 'POST')
}

/**
 * 惩罚事件详情
 */
export const getOrderPunishDetailApi = async (data) => {
    return http('/galaxyOrder/orderPunishDetail', data, 'POST')
}

/**
 * 惩罚事件列表
 */
export const getOrderPunishListApi = async (data) => {
    return http('/galaxyOrder/orderPunishList', data, 'POST')
}


/**
 * 飞猪工单列表
 */
export const getFliggyCaseListApi = async (data) => {
    return http('/galaxyOrder/getFliggyCaseList', data, 'POST')
}

/**
 * 飞猪工单列表状态数量统计
 */
export const getFliggyCaseStatusCountApi = async (data) => {
    return http('/galaxyOrder/FliggyCaseStatusCount', data, 'POST')
}

/**
 * 飞猪工单列表状态数量统计
 */
export const fliggyCaseReadApi = async (data) => {
    return http('/galaxyOrder/FliggyCaseRead', data, 'POST')
}

/**
 * 获取飞猪工单待回复数量
 */
export const getFliggyCaseReplyFCountApi = async (data) => {
    return http('/galaxyOrder/FliggyCaseReplyFCount', data, 'POST')
}

/**
 * 飞猪工单详情
 */
export const getFliggyCaseDetailApi = async (data) => {
    return http('/galaxyOrder/getFliggyCaseDetail', data, 'POST')
}

/**
 * 飞猪工单创建
 */
export const createFliggyCaseApi = async (data) => {
    return http('/galaxyOrder/createFliggyCase', data, 'POST')
}

/**
 * 飞猪工单回复
 */
export const replyFliggyCaseApi = async (data) => {
    return http('/galaxyOrder/replyFliggyCase', data, 'POST')
}
