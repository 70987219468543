<style lang="scss" scoped>
.eTrip-common {
    height: 100vh;
    overflow: hidden;
    display: flex;
    width: 100%;
    /*min-height: 500px;*/
}

.user-info-header {
    padding-top: 12px;
    min-width: 1200px;

    .header-unfold-btn {
        .fold-state-btn {
            display: inline-block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            cursor: pointer;
            border-radius: 4px;

            i {
                font-size: 26px;
            }

            &:hover {
                color: #409EFF;
                background-color: #ecf5ff;
            }

        }

    }

    .company-name {
        display: inline-block;
        margin-right: 15px;
        font-weight: normal;

        span {
            display: inline-block;
        }
    }

    .header-login-info {
        height: 40px;
        line-height: 40px;
        text-align: right;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        font-weight: 600;
        font-family: 'PingFangSC-Regular';

        .user-exit-btn {
            width: 40px;
            height: 40px;
            line-height: 40px;
            padding: 0;
            border-radius: 50%;
            border: 0;
            background: #f1f1f1;
            color: #329BF7;
            margin-left: 5px;
            transition: .1s;

            &:hover {
                background: #c6cdd6;
                transition: .1s;
            }
        }
    }

}
</style>
<style lang="scss">
.eTrip-home-page {
    min-width: 1200px;
}

.user-info-header {
    &.el-header {
        background-color: #FFFFFF;
        color: #333;
    }

}

.eTrip-home-cont {
    overflow: hidden;
    overflow-x: auto;

    .main-view-tabs {
        padding: 20px 28px 0 28px;
        background: #f8f8f8;
        border: 0 !important;
        min-width: 1144px;

        .el-tabs__content {
            display: none;
        }

        &.el-tabs--border-card {
            box-shadow: none;
        }

        &.el-tabs--border-card > .el-tabs__header {
            background: transparent;
            /*border-bottom: 0;*/
            border-bottom: 10px solid #fff;

            .el-tabs__item {
                border: 0;
                outline: none;

                &.is-focus {
                    border: 0;
                    box-shadow: none;
                }
            }

        }

        .el-tabs__nav {
            outline: none;
            background: rgba(0, 0, 0, .06);
        }

        .el-tabs__nav-next, .el-tabs__nav-prev {
            line-height: 40px;
            width: 40px;
            text-align: center;
            background: #f5f7fa;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .12), 0 0 6px 0 rgba(0, 0, 0, .04);
        }

        .el-tabs__nav-wrap.is-scrollable {
            padding: 0 40px;
        }
    }
}
</style>
<template>
    <div class="eTrip-common">
        <app-aside :isFoldState="isFoldState" v-if="isLogin"></app-aside>
        <el-container class="eTrip-home-cont" :style="{'width':(isFoldState?'64px':'calc(100vw - 220px)')}">
            <el-header class="user-info-header" height="64px" v-show="isLogin">
                <el-row>
                    <el-col :span="4" class="header-unfold-btn">
                        <div class="fold-state-btn" @click="foldShrinkClick">
                            <i class="icon icon-fold-shrink-left" v-if="!isFoldState"></i>
                            <i class="icon icon-fold-shrink-right" v-if="isFoldState"></i>
                        </div>
                    </el-col>
                    <el-col v-if="userInfo.loginuserDto&&userInfo.loginuserDto.loginName" :span="20"
                            class="header-login-info">
                        <message></message>
                        <div class="company-name">
                            <span v-if="!['9'].includes(userInfo.userType)">
                                {{ userInfo.userType | userTypeDesc }}：
                            </span>
                            <span v-if="['9'].includes(userInfo.userType)">
                              {{ userInfo.roleName }}：
                            </span>
                            <span v-if="userInfo.companyName">
                                {{
                                    userInfo.userType === '0' ? userInfo.roleName : userInfo.companyName
                                }}
                            </span>
                        </div>

                        <el-dropdown trigger="click" style="margin-right: 20px" :hide-on-click="false"
                                     @command="handleCommand">
                            <el-button type="text" style="color: rgba(0,0,0,0.65);"
                                       v-if="userInfo.loginuserDto.loginName">
                                <span>用户名：<span>{{ userInfo.loginuserDto.loginName }}</span></span>
                                <i class="el-icon-caret-bottom"></i></el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="forget">修改密码</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>

                        <!--用户名：<span>{{userInfo.companyName}}</span>-->
                        <el-button type="info" @click="handleLoginOut" class="user-exit-btn">退出</el-button>
                    </el-col>
                </el-row>
            </el-header>
            <update-password :show-visible.sync="showVisible"
                             @update:close="showVisible=false"
                             @update:password="updagePassword">
            </update-password>
            <!--            <app-notice/>-->
            <el-tabs class="main-view-tabs"
                     v-show="isLogin"
                     v-model="activeIndex"
                     type="border-card"
                     closable
                     @tab-click="tabClick"
                     v-if="options.length"
                     @tab-remove="tabRemove">
                <el-tab-pane
                    :key="item.name"
                    v-for="item in options"
                    :label="item.name"
                    :fullPath="item.fullPath"
                    :name="item.route">
                </el-tab-pane>
            </el-tabs>
            <transition name="slide" :duration="0">
                <keep-alive :include="pageNames">
                    <router-view :class="{'eTrip-home-page':options.length}"></router-view>
                </keep-alive>
            </transition>
        </el-container>

    </div>

</template>

<script type="text/ecmascript-6">
import appAside from '../aside/index.vue'
import {backendLogout} from '@/components/account/service'
import updatePassword from "@/components/updatePassword/index"
import {clearUserLoginInfo} from '@/common/js/user/'
import {findItem} from '@/common/js/common'
import {userTypes} from '@/data/index'
// import AppNotice from "@/components/app-notice/index.vue";
import Message from './message.vue'

export default {
    //定义模版数据
    data() {
        return {
            isFoldState: false,
            showVisible: false,
            fullWidth: document.documentElement.clientWidth
        }
    },
    filters: {
        userTypeDesc(value) {
            return findItem(userTypes, value)
        }
    },
    components: {
        appAside,
        updatePassword,
        Message
        // AppNotice
    },
    //计算属性
    computed: {
        options() {
            return this.$store.state.options;
        },
        pageNames() {
            let list = [];
            this.$store.state.options.forEach((item) => {
                list.push(item.route.split('/')[1]);
            })
            return list.toString();
        },
        activeIndex: {
            get() {
                console.log(this.$store.state.activeIndex);
                return this.$store.state.activeIndex;
            },
            set(val) {
                this.$store.commit('set_active_index', val);
            }
        },
        userInfo() {
            return this.$store.state.loginUserInfo
        },
        isLogin() {
            return this.$store.state.eTripToken
        }
    },
    //主件被加载完成
    mounted: function () {
        window.onresize = () => {
            return (() => {
                window.fullWidth = document.documentElement.clientWidth
                this.fullWidth = window.fullWidth
            })()
        }
        if (this.fullWidth < 1400) this.isFoldState = true

    },
    //定义事件方法
    methods: {
        tabClick(tab) {
            this.$router.push({path: tab.$attrs.fullPath});
        },
        foldShrinkClick() {  // 展开收缩侧边栏
            this.isFoldState = !this.isFoldState;
        },
        tabRemove(targetName) {
            // 首页不可删除
            if (targetName == '/home') {
                return;
            }
            this.$store.commit('delete_tabs', targetName);

            if (this.activeIndex === targetName) {
                // 设置当前激活的路由
                if (this.options && this.options.length >= 1) {
                    this.$store.commit('set_active_index', this.options[this.options.length - 1].route);
                    this.$router.push({path: this.activeIndex});
                } else {
                    this.$router.push({path: '/home'});
                }
            }
        },
        async handleLoginOut() {
            backendLogout(this);
            clearUserLoginInfo(this);
            this.$message.success(" 退出登录成功!")
            if (this.$route.path !== '/home')
                this.$router.replace('/home')
            this.$store.commit("clear_tabs");
            this.$store.commit('add_tabs', {route: '/home', name: '首页'});
            this.$store.dispatch("CHANGE_LOGIN_OR_REGISTER", {
                type: 0,
                show: true
            });
        },
        handleCommand() {
            this.showVisible = true;
        },
        async updagePassword(val) {
            let loginUserInfo = this.$store.state.loginUserInfo;
            let form = {
                "loginuserId": loginUserInfo.loginuserId,
                "mobile": loginUserInfo.loginuserDto.mobile,
                "newPwd": val.newPassword,
                "orignPwd": val.oldPassword,
                "userToken": loginUserInfo.token
            }
            try {
                let res = await this.http('/galaxyLoginuserApi/doGalaxyLoginuserApiModifyPwd', form, 'POST');
                if (res.success) {
                    this.$message({
                        message: '修改密码成功',
                        type: 'success'
                    })
                    this.showVisible = false;
                } else {
                    this.messageError(res.errors[0].message)
                }
            } catch (e) {
                this.messageError()
            }
        }
    },
    //监听模版变量
    watch: {
        '$route'(to) {
            let flag = false;
            for (let option of this.options) {
                if (option.name === to.meta.title) {
                    flag = true;
                    this.$store.commit('set_active_index', to.path);
                    option.route = to.path;
                    option.fullPath = to.fullPath;
                    this.$store.commit('change_tabs', this.options);
                    break;
                }
            }
            if (!flag) {
                this.$store.commit('add_tabs', {
                    route: to.path,
                    name: to.meta.title,
                    fullPath: to.fullPath
                });
                this.$store.commit('set_active_index', to.path);
                if (to.meta.title === "新增产品") {
                    this.$store.dispatch("addProduct", {
                        productId: false,
                        resourceId: -1,
                        productOperatorId: false,
                        productNo: false
                    });
                }
                if (to.meta.title === "编辑产品") {
                    this.$store.dispatch("editProduct", {
                        productId: false,
                        resourceId: -1,
                        productOperatorId: false,
                        productNo: false
                    });
                }
            }
            this.view = to.meta.title;
        },
        fullWidth(val) {
            if (!this.timer) {
                this.fullWidth = val
                this.timer = true
                let that = this
                setTimeout(function () {
                    that.timer = false
                }, 400)
            }
        }
    }

}
</script>
