import ApiCascader from './form/base/ApiCascader'
import ApiSelect from './form/base/ApiSelect'
import ApiTreeSelect from './form/base/ApiTreeSelect'
import ApiRadio from './form/base/ApiRadio'
import ApiRadioNew from './form/base/ApiRadioNew'
import ApiCheckbox from './form/base/ApiCheckbox'
export default {
    install (Vue) {
        Vue.component('ApiCascader', ApiCascader)
        Vue.component('ApiTreeSelect', ApiTreeSelect)
        Vue.component('ApiSelect', ApiSelect)
        Vue.component('ApiRadio', ApiRadio)
        Vue.component('ApiRadioNew', ApiRadioNew)
        Vue.component('ApiCheckbox', ApiCheckbox)
    }
}
